
.btn {
    font-weight: 700;
    transition: .5s;
}

.btn:hover {
    -webkit-box-shadow: 0 8px 6px -6px #555555;
    -moz-box-shadow: 0 8px 6px -6px #555555;
    box-shadow: 0 8px 6px -6px #555555;
}

.btn-primary {
    color: #FFFFFF;
}

.btn-square {
    width: 36px;
    height: 36px;
}

.btn-sm-square {
    width: 28px;
    height: 28px;
}

.btn-lg-square {
    width: 46px;
    height: 46px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 30px;
    bottom: 0;
    border-radius: 50% 50% 0 0;
    z-index: 99;
}

.navbar-light .navbar-nav .nav-link {
    font-family: 'Jost', sans-serif;
    position: relative;
    margin-left: 30px;
    padding: 30px 0;
    font-size: 18px;
    font-weight: 700;
    color: var(--dark);
    outline: none;
    transition: .5s;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
    color: var(--primary);
}

@media (min-width: 1360px) {
    .navbar-light .navbar-nav .nav-link::before {
        position: absolute;
        content: "";
        width: 0;
        height: 5px;
        bottom: 0;
        left: 50%;
        background: var(--primary);
        transition: .5s;
    }

    .navbar-light .navbar-nav .nav-link:hover::before,
    .navbar-light .navbar-nav .nav-link.active::before {
        width: 100%;
        left: 0;
    }
}

@media (max-width: 1359.98px) {
    .navbar-light .navbar-nav .nav-link  {
        margin-left: 0;
        padding: 10px 0;
    }
}

.service-item {
    position: relative;
    height: 350px;
    padding: 0 30px;
    transition: .5s;
}

.service-item .service-icon {
    width: 150px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary);
    border-radius: 50%;
    transform: rotate(-14deg);
}

.service-item .service-icon i {
    transform: rotate(15deg);
}

.service-item a.btn {
    position: absolute;
    width: 60px;
    bottom: -48px;
    left: 50%;
    margin-left: -30px;
    opacity: 0;
}

.service-item:hover a.btn {
    bottom: -24px;
    opacity: 1;
}

@media (min-width: 576px) {
    .team-item .row {
        height: 450px;
    }
}

.team-carousel .owl-nav {
    position: absolute;
    padding: 0 45px;
    width: 100%;
    height: 45px;
    top: calc(50% - 22.5px);
    left: 0;
    display: flex;
    justify-content: space-between;
}

.team-carousel .owl-nav .owl-prev,
.team-carousel .owl-nav .owl-next {
    position: relative;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background: var(--primary);
    border-radius: 45px;
    font-size: 22px;
    transition: .5s;
}

.team-carousel .owl-nav .owl-prev:hover,
.team-carousel .owl-nav .owl-next:hover {
    background: var(--dark);
}

.testimonial-carousel .owl-dots {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.testimonial-carousel .owl-dot {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    width: 20px;
    height: 20px;
    background: var(--light);
    border: 2px solid var(--primary);
    border-radius: 20px;
    transition: .5s;
}

.testimonial-carousel .owl-dot.active {
    width: 40px;
    height: 40px;
    background: var(--primary);
}

.testimonial-carousel .owl-item img {
    width: 150px;
    height: 150px;
}

:root {
    /* --primary: #0463FA; */
    --primary: #007902;
    --light: #EFF5FF;
    --dark: #1B2C51;
}

.fw-medium {
    font-weight: 500 !important;
}

.fw-bold {
    font-weight: 700 !important;
}

.fw-black {
    font-weight: 900 !important;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 99;
}


/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}


/*** Button ***/
.btn {
    font-weight: 500;
    transition: .5s;
}

.btn.btn-primary,
.btn.btn-secondary {
    color: #FFFFFF;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
}


/*** Navbar ***/
.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
}

.navbar .navbar-nav .nav-link {
    margin-right: 30px;
    padding: 25px 0;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
    color: var(--primary);
}

@media (max-width: 1359.98px) {
    .navbar .navbar-nav .nav-link  {
        margin-right: 0;
        padding: 10px 0;
    }

    .navbar .navbar-nav {
        border-top: 1px solid #EEEEEE;
    }
}

.navbar .navbar-brand,
.navbar a.btn {
    height: 75px;
}

.navbar .navbar-nav .nav-link {
    color: var(--dark);
    font-weight: 500;
}

.navbar.sticky-top {
    top: -100px;
    transition: .5s;
}

@media (min-width: 1360px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        border: none;
        margin-top: 0;
        top: 150%;
        opacity: 0;
        visibility: hidden;
        transition: .5s;
    }

    .navbar .nav-item:hover .dropdown-menu {
        top: 100%;
        visibility: visible;
        transition: .5s;
        opacity: 1;
    }
}


/*** Header ***/
.header-carousel .owl-carousel-text {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 3rem;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 0 0 30px rgba(0, 0, 0, .1);
    background-color: rgba(0,0,0,0.3);
}

.header-carousel .owl-nav {
    position: absolute;
    width: 200px;
    height: 45px;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
}

.header-carousel .owl-nav .owl-prev,
.header-carousel .owl-nav .owl-next {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background: transparent;
    border: 1px solid #FFFFFF;
    border-radius: 45px;
    font-size: 22px;
    transition: .5s;
}

.header-carousel .owl-nav .owl-prev:hover,
.header-carousel .owl-nav .owl-next:hover {
    background: var(--primary);
    border-color: var(--primary);
}

.header-carousel .owl-dots {
    position: absolute;
    height: 45px;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-carousel .owl-dot {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    width: 15px;
    height: 15px;
    background: transparent;
    border: 1px solid #FFFFFF;
    border-radius: 15px;
    transition: .5s;
}

.header-carousel .owl-dot::after {
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    top: 4px;
    left: 4px;
    background: #FFFFFF;
    border-radius: 5px;
}

.header-carousel .owl-dot.active {
    background: var(--primary);
    border-color: var(--primary);
}

.page-header {
    /* background: url(../img/header-page.jpg) top center no-repeat; */
    background-size: cover;
    text-shadow: 0 0 30px rgba(0, 0, 0, .1);
}

.breadcrumb-item + .breadcrumb-item::before {
    color: var(--light);
}


/*** Service ***/
.service-item {
    transition: .5s;
}

.service-item:hover {
    margin-top: -10px;
    box-shadow: 0 .5rem 1.5rem rgba(0, 0, 0, .08);
}

.service-item .btn {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 40px;
    white-space: nowrap;
    overflow: hidden;
    transition: .5s;
}

.service-item:hover .btn {
    width: 140px;
}


/*** Feature ***/
@media (min-width: 1360px) {
    .container.feature {
        max-width: 100% !important;
    }

    .feature-text  {
        padding-left: calc(((80% - 960px) / 2) + .75rem);
    }
}

@media (min-width: 1200px) {
    .feature-text  {
        padding-left: calc(((80% - 960px) / 2) + .75rem);
    }
}

@media (min-width: 1400px) {
    .feature-text  {
        padding-left: calc(((80% - 1160px) / 2) + .75rem);
    }
}

@media (max-width: 991px ){
    .feature-text .g-4{
        padding-left: calc(((80% - 960px) / 2) + .75rem);
        display: flex;
        flex-direction: row;
        font-size: 12.5px;
        padding-left: 0%;
    }

    .feature-text h5{
        font-size: 15px;
    }

    .feature-text .rounded-circle{
        font-size: 20px;
    }
}

/*** Team ***/
.team-item img {
    position: relative;
    top: 0;
    transition: .5s;
}

.team-item:hover img {
    transform: scale(110%);
}

.team-item .team-text {
    position: relative;
    height: 100px;
    transition: .5s;
}

.team-item:hover .team-text {
    margin-top: -60px;
    height: 160px;
}

.team-item .team-text .team-social {
    opacity: 0;
    transition: .5s;
}

.team-item:hover .team-text .team-social {
    opacity: 1;
}

.team-item .team-social .btn {
    display: inline-flex;
    color: var(--primary);
    background: #FFFFFF;
    border-radius: 40px;
}

.team-item .team-social .btn:hover {
    color: #FFFFFF;
    background: var(--primary);
}

.team-item p{
    text-transform: capitalize;
}


/*** Appointment ***/
.bootstrap-datetimepicker-widget.bottom {
    top: auto !important;
}

.bootstrap-datetimepicker-widget .table * {
    border-bottom-width: 0px;
}

.bootstrap-datetimepicker-widget .table th {
    font-weight: 500;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
    padding: 10px;
    border-radius: 2px;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
    background: var(--primary);
}

.bootstrap-datetimepicker-widget table td.today::before {
    border-bottom-color: var(--primary);
}


/*** Testimonial ***/
.testimonial-carousel::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 1;
}

.testimonial-carousel::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    height: 100%;
    width: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 1;
}

@media (min-width: 768px) {
    .testimonial-carousel::before,
    .testimonial-carousel::after {
        width: 200px;
    }
}

@media (min-width: 1360px) {
    .testimonial-carousel::before,
    .testimonial-carousel::after {
        width: 300px;
    }
}

.testimonial-carousel .owl-item .testimonial-text {
    background: var(--light);
    transform: scale(.8);
    transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-text {
    background: var(--primary);
    transform: scale(1);
}

.testimonial-carousel .owl-item .testimonial-text *,
.testimonial-carousel .owl-item .testimonial-item img {
    transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-text * {
    color: var(--light) !important;
}

.testimonial-carousel .owl-item.center .testimonial-item img {
    background: var(--primary) !important;
} 

.testimonial-carousel .owl-nav {
    position: absolute;
    width: 350px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    opacity: 0;
    transition: .5s;
    z-index: 1;
}

.testimonial-carousel:hover .owl-nav {
    width: 300px;
    opacity: 1;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
    position: relative;
    color: var(--primary);
    font-size: 45px;
    transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
    color: var(--dark);
}


/*** Footer ***/
.footer .btn.btn-social {
    margin-right: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light);
    border: 1px solid #FFFFFF;
    transition: .3s;
}

.footer .btn.btn-social:hover {
    color: var(--primary);
}

.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}

.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
}

.text-align-justify{
    text-align: justify;
}

.footer .btn.btn-link:hover {
    color: var(--primary);
    letter-spacing: 1px;
    box-shadow: none;
}

.footer .copyright {
    padding: 25px 0;
    font-size: 15px;
    border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
    color: var(--light);
}

.footer .copyright a:hover {
    color: var(--primary);
}

/* ------------------------------ Changes ----------------- */
.svg-class{
    width: 2rem;
}

.service-svg-icon{
    width: 1.5rem;
}

.our-mission{
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}

.text-align-center{
    text-align: center;
}

.logo-img{
    width: 10rem;
}

.text-primary img{
    width: 120px;
}

.copyright{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center ;
}

textarea{
    resize: none;
}

.color-black{
    color: black;
}

.bg-primary{
    /* background-color:#0463FA !important */
    background-color:#007902 !important
}

.text-primary{
    color: #007902 !important
    /* color: #0463FA !important */
}

.about{
    padding-top: 2.5rem;
    background-color: #eff5ff;
}

.service{
    padding-top: 2.5rem;
}

.features{
    padding-top: 2.5rem;
}

.doctors{
    padding-top: 2.5rem;
}

.contact{
    padding-top: 2.5rem;
}

.footer-service{
    display: flex;
    flex-direction: row;
}

.color-white{
    color: white;
}

.img-fluid{
    object-fit: fill;
}

.collaborate-item img{
    width: 15rem;
}

.collaborate-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
}

.contact-map{
    width: 100%;
    height: 100%;
}

@media (min-width: 768px) {
    .contact-map{
        width: 35rem;
    }
}

@media (max-width: 990px) {
    .contact-map{
        width: 100%;
        height: 20rem;
    }
}

@media (min-width: 768px) {
    .logo-img{
        position: absolute;
        width: 100px;
        top: 15px;
        left: 15px;
    }
}

@media (max-width: 480px) {
    .logo-img{
        position: absolute;
        width: 100px;
        top: 15px;
        left: 15px;
    }
}

.navbar {
    position: relative !important;
    z-index: 1000 !important;
    height: 5rem !important;
    width: 100% !important;
    background-color: #ffffff !important;
    justify-content: right !important;
}
  
.sticky-nav {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, .2);
}

.container-fluid{
    margin-top: 5rem !important;
    z-index: 1001 !important;
}

.team-item{
    width: 95%;
    height: 100%;
}

@media (max-width: 480px) {
    #navbar{
        position: absolute;
        width: 100px;
        top: 15px;
        left: 15px;
    }
}

.desktop-view{
    display: block;
}

.mobile-view{
    display: none;
}

.navbar-mobile{
    display: none;
}

@media (max-width: 480px) {
    .desktop-view{
        display: none;
    }

    .mobile-view{
        display: block;
    }

    .navbar-mobile{
        background-color: white;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .navbar-hide{
        display: none;
    }

    .team-item{
        width: 100%;
        height: 800px;
    }

    .img-team{
        width: 100%;
    }

    .img-team img{
        width: 100%;
    }

    .react-multiple-carousel__arrow {
        position: absolute;
        outline: 0;
        transition: all .5s;
        border-radius: 35px;
        z-index: 500 !important;
        border: 0;
        background: rgba(175, 175, 175, 0.8) !important;
        min-width: 43px;
        min-height: 43px;
        opacity: 1;
        cursor: pointer;
    }
}

.bookappoint{
    background-color: #007902;
    color: white !important;
    padding: 10px !important;
    border-radius: 5px;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
}

.bookappoint:hover{
    transition: all ease-in-out 0.3s;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.3);
}

.bookappointmob{
    background-color: #007902;
    margin-right: 5px;
    color: white !important;
    font-size: 10px;
    padding: 10px !important;
    border-radius: 5px;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
}

.bookappointmob:hover{
    transition: all ease-in-out 0.3s;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.3);
}

/* Hide Contact */
.hide-contact{
    display: none;
}

.thanks img{
    width: 25%;
    margin: 10%;
}

.thanks{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.hide-all{
    display: none !important;
}

.hide-all h1, .hide-all h5, .hide-all img{
    display: none;
}

.get-all h1, .get-all h5, .get-all img{
    display: block;
}

/* Alert Box */
.alert-form{
    display: block;
    color: red;
}

.alert-hide{
    display: none;
}

.hide-timestamp{
    display: none;
}

.hide-loading{
    display: none;
}

.get-loading{
    display: block;
}

.hide-loading{
    display: none !important;
}

.hide-loading span{
    display: none !important;
}

.get-loading span{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.contact-area{
    /* width: 50%;
    height: 500px; */
    /* max-height: 450px; */
}

.height-100{
    height: 100%;
}

.loading{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 2%;
    padding: 25% 0%;
}

.form-ele{
    padding: 1% 0%;
    display: flex;
    flex-direction: column;
}

.form-ele label{
    color: black;
    padding-bottom: 5px;
    font-weight: bold;
}

.form-ele input, select{
    height: 40px;
    border: solid gainsboro 1px;
    border-radius: 5px;
    outline: none;
}

.form-ele textarea{
    border: solid gainsboro 1px;
    border-radius: 5px;
    outline: none;
}

.form-ele button{
    padding: 2.5%;
    border-radius: 5px;
    color: white;
    background-color: #019004;
    border: none;
}

.form-ele button:hover{
    background-color: #007902;
    transition: all ease-in-out 0.3s;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.3);
}

.hidden-section {
    display: none;
}
.animate__animated {
    display: block;
}

.carousel-desktop{
    display: block;
}

.carousel-mobile{
    display: none;
}

@media (max-width: 480px){
    .carousel-desktop{
        display: none;
    }

    .carousel-mobile{
        display: block;
        object-fit: contain;
    }
}